import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { CountryModel } from '../../../components/models/YapilyModels/CountryModel';
import { SingletonService } from 'singleton';
import { I18N } from 'aurelia-i18n';
import { connectTo, Store } from "aurelia-store";
import { State } from "state";
@connectTo()
@autoinject()
export class chooseCountryList {

    private locale: string = "fr";
    private countryInformation: string;
    private selectedCountry: string;
    private isError: boolean = false;
    private isCountrySelected: boolean = false;
    private errorMessage: string;
    private shaking: boolean = false;

    constructor(private router: Router,
        private singleton: SingletonService,
        private i18n: I18N,
        private state: State,
        private store: Store<State>) {
    }

    async next(country) {

        this.selectedCountry = country;
        this.countryInformation = this.selectedCountry;
        this.store.dispatch("bank.add.one.country", this.countryInformation)
        this.confirm();
    }

    confirm() {
        if (!this.selectedCountry) {
            this.isError = true;
            this.errorMessage = this.i18n.tr('choose_country_list_error');
        } else {
            this.router.navigateToRoute('choose_institution')
        }
    }

    async shakeError() {
        this.shaking = true;
        setTimeout(() => {
            this.shaking = false;
        }, 200);
    }
    goBack() {
        this.router.navigateBack()
    }
}


// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/bank_account_white.png", import.meta.url);
// Module
var code = `<template>
  <require from="./choose_institution.scss"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <section id="choose_institution" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.choose_institution.choose_institution1"></h1>
    </div>
    <form id="email" class="inputs">
      <div>
        <input type="text" id="institution_name" value.bind="search_query" onkeydown="return (event.keyCode!=13);" i18n="[placeholder]home.recherche">
        <i class="fas fa-search"></i>
      </div>
    </form>
    <div class="flex-grow"></div>
    <loader-ring class="loader-btn" if.bind="loading"></loader-ring>
    <div class="listing flex-column" if.bind="!loading">
      <div class="institution flex-row" repeat.for="currentAvailableBank of banksAvailableAndSearch"
        click.delegate="selectBank(currentAvailableBank)"
        class.bind="currentAvailableBank.id == selectedAvailableBank.id ? 'active' : ''">
        <div class="around-img">
          <img src="\${currentAvailableBank.media[0].source}">
        </div>
        <p class="title">\${currentAvailableBank.name}</p>
        <div class="flex-grow"></div>
      </div>
      <div class="institution flex-row" if.bind="banksAvailableAndSearch.length < 1"
        class.bind="currentAvailableBank.id == selectedAvailableBank.id ? 'active' : ''">
        <div class="around-img">
          <img src="${___HTML_LOADER_IMPORT_1___}">
        </div>
        <p class="title" t="bank.choose_institution.no_search_result">No bank corresponding to research</p>
        <div class="flex-grow"></div>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;
// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/flags/svg/uk.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/EU/eu_flag.png", import.meta.url);
// Module
var code = `<template>
  <require from="./create_recipients.scss"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <section id="create_recipients" class="flex-column">
    <div id="header" class="flex-row">
      <img
        click.delegate="router.navigateBack()"
        src="${___HTML_LOADER_IMPORT_0___}"
      />
      <h1 i18n="bank.create_recipients.create_recipients1"></h1>
    </div>
    <form class="flex-column inputs" submit.delegate="postRecipients()" class.bind="shaking ? 'shake' : ''" id="recipient_form">
      <div>
        <input id="recipient_name_input" value.bind="name" placeholder.bind="getRandomName()" autofocus />
      </div>
      <div>
        <div class="custom-select">
          <div
            class="lang_select_box"
            click.delegate="showFlag()"
            ref="lang_selected"
          >
            <div class="left">
              <img src="${___HTML_LOADER_IMPORT_1___}" if.bind="isUk" />
              <img src="${___HTML_LOADER_IMPORT_2___}" if.bind="!isUk" />
            </div>
            <i class="fas fa-sort-down"></i>
          </div>
          <div class="dropDown flex-column" if.bind="isDropDown">
            <div class="dropDownContent flex-column">
              <div class="lang extremity-top flex-row" click.delegate="isUK(false)" class.bind="isUk === false ? 'selected' : ''">
                <img src="${___HTML_LOADER_IMPORT_2___}" />
              </div>
              <div class="lang extremity-bottom flex-row" click.delegate="isUK(true)" class.bind="isUk == true ? 'selected' : ''">
                <img src="${___HTML_LOADER_IMPORT_1___}" />
              </div>
            </div>
          </div>
        </div>
        <input id="recipient_iban_input" value.bind="iban" placeholder="BE77 3601 0917 7142" oninput="this.value = this.value.toUpperCase()" if.bind="!isUk"/>
        <input id="recipient_account_number_input" value.bind="account_number" placeholder="Account number" oninput="this.value = this.value.toUpperCase()" if.bind="isUk"/>
      </div>
      <div if.bind="isUk">
        <input
          value.bind="routing_number"
          placeholder="Routing"
        />
      </div>
      <div if.bind="isUk">
        <input
          value.bind="bic"
          placeholder="BIC"
          oninput="this.value = this.value.toUpperCase()"
        />
      </div>
    </form>
    <div class="flex-grow"></div>
    <div
      class="error"
      if.bind="displayError"
      class.bind="shaking ? 'shake' : ''"
    >
      <p>\${errorMessage}</p>
    </div>
    <button
      class="btn btn-primary"
      click.delegate="postRecipients()"
      type="submit"
      form="recipient_form"
      ref="authorized_button"
      disabled.bind="!(name && iban) && !(name && account_number && routing_number) || isLoading"
    >
      <span i18n="bank.create_recipients.create_recipients2" if.bind="!isLoading"></span>
      <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;
import { YapilyHttpClient } from './../../../http_clients/YapilyHttpClient';
import { UsersHttpClients } from './../../../http_clients/UsersHttpClients';
import { CountryModel } from '../../../components/models/YapilyModels/CountryModel';
import { autoinject, computedFrom, inject } from 'aurelia-framework';
import { AuthorizeStep, AuthService } from 'aurelia-auth';
import { Router } from 'aurelia-router';
import { checkResponseStatus } from '../../../http_clients/checkResponseStatus';
import { SingletonService } from 'singleton';
import ENDPOINTS from 'endpoints';
import BankaService from 'services/banka.service';
import { YapilyBanksDetailsModel } from 'components/models/YapilyModels/YapilyBanksDetailsModel';
import { YapilyBankDetailsModel } from 'components/models/YapilyModels/YapilyBankDetailsModel';
import { Store, connectTo } from 'aurelia-store';
import { State } from 'state';

@connectTo()
@autoinject()
export class ChooseInstitution {
  private countryInformation: string;
  private loading: boolean = true;
  private isBankSelected: boolean = false;
  private allExistingBanks: YapilyBanksDetailsModel = new YapilyBanksDetailsModel();
  private banksAvailable: Array<YapilyBankDetailsModel> = [];
  private selectedAvailableBank: YapilyBankDetailsModel = new YapilyBankDetailsModel();
  private shaking: boolean = false;
  private myBankFromCountry;
  private search_query: string = "";
  private state: State;

  constructor(
    private router: Router,
    private yapilyHttpClient: YapilyHttpClient,
    private singleton: SingletonService,
    private bankaService: BankaService,
    private store: Store<State>) {
    this.countryInformation = this.singleton.getCountries();
  }

  attached() {
    this.singleton.setBankToAdd(null);
    this.getBanksAvailable()
  }

  bind () {
    this.countryInformation = this.state.selectCountryBank; 
  }

  /**
   * This function gets all the available open banking institution
   * keeps only the one corresponding to the selected country
   */
  async getBanksAvailable() {
    this.allExistingBanks = await this.bankaService.getBanksAvailable(this.countryInformation);
    this.banksAvailable = await this.keepOnlyBanksFromCountry(this.allExistingBanks, this.countryInformation);
    this.loading = false;
  }

  async keepOnlyBanksFromCountry(allExistingBanks: YapilyBanksDetailsModel, country: string) {
    let paypal = "European Union"
    let banksThatMatch: Array<YapilyBankDetailsModel> = []; //Empty Initialisation
    for (let i = 0; i < this.allExistingBanks.data.length; i++) {
      let countriesInThisBank = this.allExistingBanks.data[i].countries.length || 0
      for (let o = 0; o < countriesInThisBank; o++) {
        try {
          if(this.allExistingBanks.data[i].countries[o].displayName == paypal)
          banksThatMatch.push(this.allExistingBanks.data[i])
          
          if (this.allExistingBanks.data[i].countries[o].countryCode2 == this.countryInformation) {

            banksThatMatch.push(this.allExistingBanks.data[i])
          } else {
          }

        } catch (e) {
          console.log("Error:" + e)
        }

      }
    }
    return banksThatMatch;
  }

  @computedFrom('banksAvailable', 'search_query')
  get banksAvailableAndSearch() {
    return this.banksAvailable.filter((currentBank) => {
      return currentBank.fullName.toLowerCase().includes(this.search_query.toLocaleLowerCase());
    })
  }

  async selectBank(availableBankToSelect: YapilyBankDetailsModel) {
    this.loading = false;
    this.selectedAvailableBank = availableBankToSelect;
    this.isBankSelected = true;
    this.store.dispatch("bank.add.one.institution", this.selectedAvailableBank);
    this.router.navigateToRoute('agreements_users')
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  goBack() {
    this.router.navigateBack()
  }
}

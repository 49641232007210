// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/flags/svg/austria.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/flags/svg/belgium.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/flags/svg/denmark.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/img/flags/svg/estonia.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/img/flags/svg/finland.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/static/img/flags/svg/france.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/static/img/flags/svg/germany.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_8___ = new URL("/static/img/flags/svg/iceland.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_9___ = new URL("/static/img/flags/svg/ireland.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_10___ = new URL("/static/img/flags/svg/italy.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_11___ = new URL("/static/img/flags/svg/latvia.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_12___ = new URL("/static/img/flags/svg/lithuania.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_13___ = new URL("/static/img/flags/svg/netherlands.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_14___ = new URL("/static/img/flags/svg/norway.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_15___ = new URL("/static/img/flags/svg/poland.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_16___ = new URL("/static/img/flags/svg/spain.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_17___ = new URL("/static/img/flags/svg/sweden.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_18___ = new URL("/static/img/flags/svg/uk.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./choose_country_list.scss"></require>
  <section id="choose_country_list" class="flex-column">
    <div id="header" class="flex-row" style="margin-bottom: 1rem;">
      <img click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.choose_country1"></h1>
    </div>
    <div class="countries flex-column">
      <div class="country_card flex-row" click.delegate="next('AT')" class.bind="selectedCountry == 'AT' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_1___}">
        <p>Austria</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('BE')" class.bind="selectedCountry == 'BE' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_2___}">
        <p>Belgium</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('DK')" class.bind="selectedCountry == 'DK' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_3___}">
        <p>Denmark</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('EE')" class.bind="selectedCountry == 'EE' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_4___}">
        <p>Estonia</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('FI')" class.bind="selectedCountry == 'FI' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_5___}">
        <p>Finland</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('FR')" class.bind="selectedCountry == 'FR' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_6___}">
        <p>France</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('DE')" class.bind="selectedCountry == 'DE' ? 'active' : ''" >
        <img src="${___HTML_LOADER_IMPORT_7___}">
        <p>Germany</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('IS')" class.bind="selectedCountry == 'IS' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_8___}">
        <p>Iceland</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('IE')" class.bind="selectedCountry == 'IE' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_9___}">
        <p>Ireland</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('IT')" class.bind="selectedCountry == 'IT' ? 'active' : ''" >
        <img src="${___HTML_LOADER_IMPORT_10___}">
        <p>Italy</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('LV')" class.bind="selectedCountry == 'LV' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_11___}">
        <p>Latvia</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('LT')" class.bind="selectedCountry == 'LT' ? 'active' : ''" >
        <img src="${___HTML_LOADER_IMPORT_12___}">
        <p>Lithuania</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('NL')" class.bind="selectedCountry == 'NL' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_13___}">
        <p>Netherlands</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('NO')" class.bind="selectedCountry == 'NO' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_14___}">
        <p>Norway</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('PL')" class.bind="selectedCountry == 'PL' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_15___}">
        <p>Poland</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('ES')" class.bind="selectedCountry == 'ES' ? 'active' : ''" >
        <img src="${___HTML_LOADER_IMPORT_16___}">
        <p>Spain</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('SE')" class.bind="selectedCountry == 'SE' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_17___}">
        <p>Sweden</p>
      </div>
      <div class="country_card flex-row" click.delegate="next('GB')" class.bind="selectedCountry == 'GB' ? 'active' : ''">
        <img src="${___HTML_LOADER_IMPORT_18___}">
        <p>UK</p>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="error" if.bind='isError'>
      <p>
        \${errorMessage}
      </p>
    </div>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { RecipientModel } from "components/models/RecipientModel";
import { RecipientsHttpClient } from "../../../http_clients/RecipientsHttpClient";
import { json } from "aurelia-fetch-client";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from "aurelia-i18n";
import * as IBAN from "iban";
import { SingletonService } from "singleton";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject()
export class CreateRecipients {
  private name: string;
  private recipientsInformation: RecipientModel = new RecipientModel();
  private iban: string;
  private account_number: string;
  private routing_number: string;
  private bic: string;
  private displayError: boolean = false;
  private errorMessage: string = "";
  private shaking: boolean;
  private isLoading: boolean = false;
  private recipient = {};
  private isBlockchain: boolean = false;
  private isUk: boolean = false;

  constructor(
    private router: Router,
    private recipientsHttpClient: RecipientsHttpClient,
    private i18n: I18N,
    private singleton: SingletonService,
    private ea: EventAggregator,
  ) {}

  activate(UrlParams: { search, isUk, isBlockchain}) {
    
    this.checkValue(UrlParams.search, UrlParams.isUk, UrlParams.isBlockchain);
  }

  bind() {
  }


  async postRecipients() {
    try {
      if (this.isUk == false) {
        if (!this.isBlockchain) {
          if (IBAN.isValid(this.iban)) {
            this.displayError = false;
            this.recipientsInformation.iban = this.iban;
            this.recipientsInformation.name = this.name;
            this.recipientsInformation.isBlockchain = false;
            this.ea.publish("recipient.add.one.backend",this.recipientsInformation);
          }
          else {
            this.displayError = true;
            this.errorMessage = "This IBAN doesn't exist";
          }
        }
        else {
          this.displayError = false;
          this.recipientsInformation.iban = this.iban;
          this.recipientsInformation.name = this.name;
          this.recipientsInformation.isBlockchain = true;
          this.ea.publish("recipient.add.one.backend",this.recipientsInformation);
        }
        this.router.navigateToRoute("contacts", {page: "mine"});
      } else {
        await this.createRecipientForUk();
      }
    } catch (e) {
      console.log(e);
      this.handleErrors(e);
    }
  }

  async createRecipientForUk() {
    try {
      this.recipientsInformation.routing_number = this.routing_number;
      this.recipientsInformation.account_number = this.account_number;
      this.recipientsInformation.bic = this.bic;
      this.recipientsInformation.name = this.name;
      this.recipientsInformation.isUk = this.isUk;
      this.ea.publish("recipientUK.add.one.backend",this.recipientsInformation)
      this.router.navigateToRoute("contacts", {page: "mine"});
    } catch (e) {
      await this.handleErrors(e);
    }
  }

  async handleErrors(e) {
    this.isLoading = true;
    if (e == "L'IBAN ou le routing number existe déjà pour cet owner_id" || "l'IBAN existent déjà pour cet owner_id") {
      this.displayError = true;
      this.errorMessage = this.i18n.tr("bank.create_recipients.already_registered");
      this.isLoading = false;
    }
  }

  getRandomName() {
    let randomNames = [
      "Mario",
      "Luigi",
      "Shrek",
      "Terminator",
      "Jack Sparrow",
      "Harry Potter",
      "Jimmy",
    ];
    return randomNames[Math.floor(Math.random() * randomNames.length)];
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  private isDropDown: boolean = false;
  

  showFlag() {
    this.isDropDown = !this.isDropDown;
  }

  async isUK(isUk: boolean) {
    this.isUk = isUk;
    this.isDropDown = !this.isDropDown;
  }

  checkValue(search: string, isUk: string, isBlockchain: string) {
    if(isUk == "true") {
      this.isUk = true;
    }
    else {
      this.isUk = false;
    }

    if (isBlockchain == "true") {
      this.isBlockchain = true;
    }
    else {
      this.isBlockchain = false;
    }
    if (this.isUk == true) {
      this.account_number = search;
    }
    else {
      this.iban = search;
    }
  }
}
